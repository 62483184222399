// ##### /src/App.tsx #####

import React, { useState, useEffect } from 'react';
import ErrorBoundary from './components/ErrorBoundary';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import { sha512 } from 'js-sha512';
import { InputFields } from './types/inputTypes';
import { logRequestDetails } from './lib/logRequest';
import { getDeviceFingerprint } from './lib/deviceFingerprint';

const API_KEY_ERROR = 'Enter apiKey';
const PAY_MODE_ERROR = 'Accepted values: [0, 1, 2]';
const HASH_AMOUNT_ERROR = 'Amount must be a whole number between 100 and 99999';
const MUPID_ERROR = 'Merchant Unique Payment ID - mUPID is required';
const TIMESTAMP_ERROR = 'Timestamp is required and must be in `yyyy-MM-ddTHH:mm:ss` format';
const USERNAME_ERROR = 'Username is required';
const PASSWORD_ERROR = 'Password is required';

const App: React.FC = () => {
  const [inputFields, setInputFields] = useState<InputFields>({
    payMode: '',
    hashAmount: '',
    mupid: '',
    timestamp: '',
    apiKey: '',
    username: '',
    password: ''
  });
  const [generatedHash, setGeneratedHash] = useState('');
  const [errors, setErrors] = useState<Partial<Record<keyof InputFields, string>>>({});
  const [url, setUrl] = useState('');
  const [parsedUrl, setParsedUrl] = useState<[string, string][]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const getCurrentUTCTimestamp = () => {
    const now = new Date();
    return now.toISOString().slice(0, 19).replace('T', 'T');
  };
  useEffect(() => {
    setInputFields((prevFields: InputFields) => ({
      ...prevFields,
      timestamp: getCurrentUTCTimestamp()
    }));
  }, []);
  const validateInputs = () => {
    const newErrors: Partial<Record<keyof InputFields, string>> = {};
    if (!inputFields.apiKey) newErrors.apiKey = API_KEY_ERROR;
    if (!['0', '1', '2'].includes(inputFields.payMode)) newErrors.payMode = PAY_MODE_ERROR;
    if (!/^\d+$/.test(inputFields.hashAmount) || parseInt(inputFields.hashAmount) < 100 || parseInt(inputFields.hashAmount) > 99999)
      newErrors.hashAmount = HASH_AMOUNT_ERROR;
    if (!inputFields.mupid) newErrors.mupid = MUPID_ERROR;
    if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(inputFields.timestamp)) newErrors.timestamp = TIMESTAMP_ERROR;
    if (!inputFields.username) newErrors.username = USERNAME_ERROR;
    if (!inputFields.password) newErrors.password = PASSWORD_ERROR;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const generateHash = async () => {
    if (validateInputs()) {
      setIsLoading(true);
      const hashString = `${inputFields.apiKey}|${inputFields.username}|${inputFields.password}|${inputFields.payMode}|${inputFields.hashAmount}|${inputFields.mupid}|${inputFields.timestamp}`;
      const generatedSHA512Hash = sha512(hashString);
      logRequestDetails({
        userAgent: window.navigator.userAgent,
        url: window.location.href,
        payload: inputFields,
        fingerprintDetails: {},
        hashString,
        hashValue: generatedSHA512Hash,
        deviceFingerprint: getDeviceFingerprint()
      });
      setGeneratedHash(generatedSHA512Hash);
      setIsLoading(false);
    }
  };
  const parseUrl = () => {
    try {
      let queryString = url;
      if (url.includes('?')) {
        queryString = url.split('?')[1];
      }
      const pairs = queryString.split('&');
      const parsedPairs: [string, string][] = pairs.map(pair => {
        const [key, value] = pair.split('=');
        return [decodeURIComponent(key), decodeURIComponent(value || '')];
      });
      setParsedUrl(parsedPairs);
    } catch (error) {
      console.error('Error parsing URL:', error);
      setParsedUrl([['error', 'Invalid URL or query string']]);
    }
  };
  return (
    <ErrorBoundary>
      <div className="flex flex-col min-h-screen">
        <div className="flex flex-col bg-white">
          <MainContent
            inputFields={inputFields}
            errors={errors}
            setInputFields={setInputFields}
            generateHash={generateHash}
            isLoading={isLoading}
            generatedHash={generatedHash}
            url={url}
            setUrl={setUrl}
            parseUrl={parseUrl}
            parsedUrl={parsedUrl}
          />
        </div>
        <Footer />
      </div>
    </ErrorBoundary>
  );
};

export default App;
