// ##### /src/components/jQuery/errorCodes.tsx #####

const errorData = {
  AuthenticationErrors: [
    { code: "E01", description: "Ensure both fingerprint and API key are provided." },
    { code: "E04", description: "Invalid credentials. This applies to V1 and V2 (both are deprecated)." },
    { code: "E08", description: "Invalid credentials. Make sure the fingerprint is correctly generated. Refer to the fingerprint generation logic." },
    { code: "E13", description: "The provided MerchantCode does not match the credentials." },
    { code: "E16", description: "Version cannot be empty." },
  ],
  ValidationErrors: [
    { code: "E02-*", description: "MerchantUniquePaymentId cannot be empty." },
    { code: "E03-*", description: "The fingerprint must be unique each time. Use a new MerchantUniquePaymentId and the current timestamp every time the plugin is opened." },
    { code: "E11", description: "Timestamp cannot be empty. Ensure it matches the timestamp used in the generated fingerprint." },
    { code: "E15", description: "MerchantCode cannot be empty (applies to V4 and later)." },
    { code: "E17", description: "CustomerEmail cannot be empty (applies to V4 and later)." },
  ],
  SecurityErrors: [
    { code: "E09", description: "Security violation. Close and reopen the plugin with a new fingerprint." },
    { code: "E10", description: "Security violation. Close and reopen the plugin with a new fingerprint." },
    { code: "E14", description: "Security violation. Close and reopen the plugin with a new fingerprint." },
  ],
  OtherErrors: [
    { code: "E05", description: "Ensure both fingerprint and API key are provided." },
    { code: "E06", description: "Account is not active. Contact the administrator." },
    { code: "E07", description: "The provided endpoint is not supported." },
  ],
};
export default errorData;
