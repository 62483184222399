// ##### src/pages/hashPages/HashForm.tsx #####

import React from 'react';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { Button } from '../../components/ui/button';
import { Loader2, Info } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../components/ui/tooltip';
import { InputFields } from '../../types/inputTypes';
interface HashFormProps {
  inputFields: InputFields;
  errors: Partial<Record<keyof InputFields, string>>;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  generateHash: () => void;
  isLoading: boolean;
  fieldInfo: Record<keyof InputFields, JSX.Element>;
}
const HashForm: React.FC<HashFormProps> = ({
  inputFields,
  errors,
  onInputChange,
  generateHash,
  isLoading,
  fieldInfo,
}) => {
  const renderInput = (id: keyof InputFields, placeholder: string, emoji: string) => (
    <div className="space-y-2">
      <div className="flex items-center space-x-2">
        <Label htmlFor={String(id)} className="w-1/3 text-right flex items-center">
          <span className="mr-2">{emoji}</span>
          {typeof id === 'string' ? id.charAt(0).toUpperCase() + id.slice(1) : id}
        </Label>
        <Input
          id={String(id)}
          value={inputFields[id]}
          onChange={onInputChange}
          placeholder={placeholder}
        />
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className="w-8 h-8 rounded-full p-0 bg-white text-red-600 border-red-600 hover:bg-red-100">
                <Info className="h-4 w-4" />
                <span className="sr-only">Info for {id}</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{fieldInfo[id]}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      {errors[id] && <p className="text-sm text-red-600 ml-4">{errors[id]}</p>}
    </div>
  );
  return (
    <div className="space-y-6">
      <div className="grid gap-6 md:grid-cols-2">
        <div className="space-y-4">
          {renderInput('payMode', 'Accepted values: [0, 1, 2]', '💳')}
          {renderInput('hashAmount', 'Must be a whole number between 100 and 999999', '💰')}
          {renderInput('mupid', 'Provide the Merchant Unique Payment ID', '🆔')}
          {renderInput('timestamp', 'Current UTC time', '🕒')}
        </div>
        <div className="space-y-4">
          {renderInput('apiKey', 'Enter API key', '🔑')}
          {renderInput('username', 'Enter your username', '👤')}
          {renderInput('password', 'Enter your password', '🔒')}
        </div>
      </div>
      <Button onClick={generateHash} className="w-full bg-red-600 hover:bg-red-700 text-white transition-colors duration-300 rounded-sm" disabled={isLoading}>
        {isLoading ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            Generating...
          </>
        ) : (
          <>🔐 Generate Hash</>
        )}
      </Button>
    </div>
  );
};
export default HashForm;
