// src/pages/ErrorCodesTab.tsx

import React from 'react';
import { motion } from 'framer-motion';
import ErrorCodesTable from '../components/jQuery/ErrorCodesTable';
import errorData from '../components/jQuery/errorCodes';

const ErrorCodesTab: React.FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
      className="space-y-4 p-4"
    >
      <h2 className="text-2xl font-bold mb-4">Error Codes - Work in Progress - Ignore</h2>
      <p className="mb-4">Below is a list of all possible error codes and their descriptions.</p>
      <ErrorCodesTable errorData={errorData} />
    </motion.div>
  );
};

export default ErrorCodesTab;