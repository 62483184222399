// ##### src/pages/ParserTab.tsx #####
// Add a button to copy/paste as markdown table automatically
// Move Parse URL button to left of the input field
// Do not display keys if they have '' or null values
// Add ":" after each key label

import React from 'react';
import { motion } from 'framer-motion';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { Label } from '../components/ui/label';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../components/ui/tooltip';
import { Info } from 'lucide-react';
interface ParserTabProps {
    url: string;
    setUrl: (url: string) => void;
    parseUrl: () => void;
    parsedUrl: [string, string][];
}
const ParserTab: React.FC<ParserTabProps> = ({ url, setUrl, parseUrl, parsedUrl }) => {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }} exit={{ opacity: 0 }} className="space-y-4">
            <div className="flex items-center space-x-2 mb-2">
                <Label htmlFor="url" className="text-lg font-semibold text-gray-800">
                    🔗 Enter URL or Query String
                </Label>
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button
                                variant="outline"
                                size="icon"
                                className="w-8 h-8 rounded-full p-0 bg-white text-red-600 border-red-600 hover:bg-red-100"
                            >
                                <Info className="h-4 w-4" />
                                <span className="sr-only">Info for URL</span>
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent className="bg-tooltipBg text-tooltipText p-2 rounded-md shadow-md">
                            <div className="overflow-x-auto whitespace-nowrap">
                                <p>Provide a valid URL to parse.</p>
                            </div>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </div>
            <div className="flex space-x-2">
                <Input
                    id="url"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="/Authorise?isJsPlugin=true&hideHeader=true&mode=0..."
                    className="flex-grow border-gray-300 focus:border-red-500 focus:ring-red-500 w-full"
                />
                <Button
                    onClick={parseUrl}
                    className="bg-red-600 hover:bg-red-700 text-white transition-colors duration-300 rounded-sm"
                >
                    🔍 Parse URL
                </Button>
            </div>
            <div className="p-4 bg-red-50 rounded-md border border-red-200">
                <Label>Parsed URL Parameters</Label>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                    {parsedUrl.map(([key, value], index) => (
                        <div key={index} className="flex flex-col">
                            <span className="font-medium break-all">{key}</span>
                            <span className="text-sm text-gray-600 break-all">{value}</span>
                        </div>
                    ))}
                </div>
            </div>
        </motion.div>
    );
};
export default ParserTab;
