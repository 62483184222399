// src/lib/logRequest.ts

import logger from './logger';
import { getDeviceFingerprint, DeviceFingerprint } from './deviceFingerprint';

interface LogRequestOptions {
  userAgent: string;
  url: string;
  payload: object;
  fingerprintDetails?: object;
  hashString: string;
  hashValue: string;
  deviceFingerprint?: DeviceFingerprint;
}

export const logRequestDetails = ({
  userAgent,
  url,
  payload,
  fingerprintDetails = {},
  hashString,
  hashValue,
  deviceFingerprint = getDeviceFingerprint()
}: LogRequestOptions) => {
  const logEntry = {
    UserAgent: userAgent,
    URL: url,
    Payload: payload,
    Fingerprint: fingerprintDetails,
    HashInfo: {
      hashString,
      hashValue,
    },
    DeviceFingerprint: deviceFingerprint,
  };
  logger.info('📨 ', logEntry);
};

interface HashGenerationDetails {
  inputFields: object;
  fingerprintDetails?: object;
  hashString: string;
  hashValue: string;
  deviceFingerprint?: DeviceFingerprint;
}

export const logHashGenerationDetails = ({
  inputFields,
  fingerprintDetails = {},
  hashString,
  hashValue,
  deviceFingerprint = getDeviceFingerprint()
}: HashGenerationDetails) => {
  const logEntry = {
    InputFields: inputFields,
    Fingerprint: fingerprintDetails,
    HashInfo: {
      hashString,
      hashValue,
    },
    DeviceFingerprint: deviceFingerprint,
  };

  logger.info('🔒 ', logEntry);
};