// ##### /src/components/ui/tabs.tsx ##### //

import * as React from "react"
import * as TabsPrimitive from "@radix-ui/react-tabs"
import { useLocation } from 'react-router-dom';
import { cn } from '../../lib/utils';

const Tabs = React.forwardRef<React.ElementRef<typeof TabsPrimitive.Root>, React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>>(
  ({ className, defaultValue, ...props }, ref) => {
    let tabFromUrl = defaultValue;
    try {
      const location = useLocation();
      const searchParams = new URLSearchParams(location.search);
      tabFromUrl = searchParams.get('t') || defaultValue;
    } catch (error) {
      console.warn('useLocation hook failed, falling back to defaultValue', error);
    }
    const normalizedTab = tabFromUrl ? tabFromUrl.toLowerCase() : defaultValue;
    return (
      <TabsPrimitive.Root
        defaultValue={normalizedTab}
        ref={ref}
        className={cn(className)}
        {...props}
      />
    );
  }
);
Tabs.displayName = 'Tabs';
const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "inline-flex h-12 items-center justify-center rounded-md bg-red-100 p-2 text-gray-800 shadow-md",
      className
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
      "bg-gray-100 text-gray-800 hover:bg-gray-200 border border-gray-300",
      "data-[state=active]:bg-red-600 data-[state=active]:text-white data-[state=active]:border-red-600",
      className
    )}
    {...props}
  />
));

TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "mt-2 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2",
      className
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;
export { Tabs, TabsList, TabsTrigger, TabsContent }
