// ##### /src/components/Footer.tsx ##### //

import React from 'react';
const Footer: React.FC = () => {
    return (
        <footer className="py-4 text-center text-sm text-red-600 bg-white">
            <img
                src="https://www.zenithpayments.com.au/wp-content/uploads/2023/07/sm_logo.webp"
                alt="Zenith Payments Logo"
                style={{ width: '150px', height: 'auto', margin: '0 auto' }}
            />
            <p>© 2024 Zenith Payments. All rights reserved.</p>
            <p></p>
            <p>
                2024 | ZenTools is designed to support integrators and developers by providing a range of tools and utilities to aid in
                their projects
            </p>
            <p>
                <a href="mailto:integrations@zenithpayments.com.au" className="underline hover:text-red-800 transition-colors duration-300">For support and integration queries 📧 </a>
                |   |   |
                <a href="mailto:ian@zenithpayments.com.au" className="underline hover:text-red-800 transition-colors duration-300">🐞 For bugs in app 📧 </a>
            </p>
            <a href="https://github.com/ianmenethil/ZenTools" className="underline hover:text-red-800 transition-colors duration-300" rel="noopener noreferrer" aria-label="View repo on GitHub">
                View repo on GitHub
            </a>
        </footer>
    );
};
export default Footer;
