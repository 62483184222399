// #### src/lib/logger.ts #### //

const CLOUD_FLARE_WORKER_URL = 'https://zenlogger.ianmenethil.workers.dev';
const logger = {
  info: async (message: string, data: any) => {
    try {
      const response = await fetch(CLOUD_FLARE_WORKER_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message,
          data,
        }),
      });
      if (!response.ok) {
        console.error(`Failed to send log: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error sending log:', error);
    }
  },
};
export default logger;
