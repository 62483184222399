// ##### /src/components/MainContent.tsx ##### //

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Hash } from 'lucide-react';
import TabContainer from './../pages/TabContainer';
import { InputFields } from '../types/inputTypes';
interface MainContentProps {
  inputFields: InputFields;
  errors: Partial<Record<keyof InputFields, string>>;
  setInputFields: (fields: InputFields) => void;
  generateHash: () => void;
  isLoading: boolean;
  generatedHash: string;
  url: string;
  setUrl: (url: string) => void;
  parseUrl: () => void;
  parsedUrl: [string, string][];
}
const fieldInfo: Record<keyof InputFields, JSX.Element> = {
  payMode: (
    <>
      Pay Mode
      <br />0 For fixed amount
      <br />1 For tokenization amount
      <br />2 For custom amount
    </>
  ),
  hashAmount: (
    <>
      Amount to be hashed.
      <br />
      Must be a whole number between 100 and 999999
      <br />
      For example
      <br />
      If payloadAmount == 12.34$
      <br />
      hashAmount == 1234
    </>
  ),
  mupid: <>Provide the Merchant Unique Payment ID.</>,
  timestamp: (
    <>
      Timestamp must be in UTC with the following format: `yyyy-MM-ddTHH:mm:ss`.
      <br />
      e.g., 2024-12-31T23:59:59
    </>
  ),
  apiKey: <>Enter a valid API key for authentication.</>,
  username: <>Enter your username.</>,
  password: <>Enter your password.</>
};
const MainContent: React.FC<MainContentProps> = ({
  inputFields,
  errors,
  setInputFields,
  generateHash,
  isLoading,
  generatedHash,
  url,
  setUrl,
  parseUrl,
  parsedUrl
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('hashgen');
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabFromUrl = searchParams.get('t');
    if (tabFromUrl === 'parser' || tabFromUrl === 'hashgen' || tabFromUrl === 'errorcodes') {
      setActiveTab(tabFromUrl);
    }
  }, [location]);
  const handleTabChange = (value: string) => {
    setActiveTab(value);
    navigate(`?t=${value}`, { replace: true });
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setInputFields({ ...inputFields, [id]: value });
  };
  return (
    <div className="flex flex-col bg-white">
      <header className="flex items-center justify-between p-4 bg-red-600 text-white shadow-lg">
        <div className="flex items-center space-x-2">
          <Hash className="h-6 w-6" />
          <span className="text-lg font-bold">🔐 ZenTools</span>
        </div>
      </header>
      <main className="container mx-auto px-4 py-8 flex-grow">
        <TabContainer
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          inputFields={inputFields}
          errors={errors}
          onInputChange={handleInputChange}
          generateHash={generateHash}
          isLoading={isLoading}
          generatedHash={generatedHash}
          url={url}
          setUrl={setUrl}
          parseUrl={parseUrl}
          parsedUrl={parsedUrl}
          fieldInfo={fieldInfo}
          />
      </main>
    </div>
  );
};
export default MainContent;
