// ##### src/pages/HashGenTab.tsx #####

import React from 'react';
import { motion } from 'framer-motion';
import HashForm from './../pages/hashPages/HashForm';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { InputFields } from '../types/inputTypes';
interface HashGenTabProps {
    inputFields: InputFields;
    errors: Partial<Record<keyof InputFields, string>>;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    generateHash: () => void;
    isLoading: boolean;
    fieldInfo: Record<keyof InputFields, JSX.Element>;
    generatedHash: string;
  }
const HashGenTab: React.FC<HashGenTabProps> = ({
    inputFields,
    errors,
    onInputChange,
    generateHash,
    isLoading,
    fieldInfo,
    generatedHash
}) => {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }} exit={{ opacity: 0 }} className="space-y-6">
            <HashForm
                inputFields={inputFields}
                errors={errors}
                onInputChange={onInputChange}
                generateHash={generateHash}
                isLoading={isLoading}
                fieldInfo={fieldInfo}
            />
            <div className="p-4 bg-red-50 rounded-md border border-red-200">
                <Label htmlFor="generatedHash">Generated Hash</Label>
                <Input id="generatedHash" value={generatedHash} readOnly className="mt-2 bg-white w-full" />
            </div>
        </motion.div>
    );
};
export default HashGenTab;
