// src/pages/InputParametersTab.tsx

import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #4a90e2;
  color: white;
  font-weight: bold;
`;

const Td = styled.td`
  padding: 12px;
  background-color: #ffffff;
  border: 1px solid #eee;
`;

const inputParameters = [
    { name: 'url', type: 'string', conditional: 'Required', remarks: 'Plugin access url. We strongly recommend v4 integration.' },
    { name: 'merchantCode', type: 'string', conditional: 'Required', remarks: 'As provided by Zenith.' },
    { name: 'apiKey', type: 'string', conditional: 'Required', remarks: 'As provided by Zenith' },
    { name: 'fingerprint', type: 'string', conditional: 'Required', remarks: 'SHA3 (SHA512) hash (v4) or SHA1 hash (v3) of specific fields.' },
    { name: 'redirectUrl', type: 'string', conditional: 'Required', remarks: 'The page will redirect to this URL with the result in the query string.' },
    { name: 'mode', type: 'int', conditional: 'Optional', remarks: '0 - Make Payment, 1 - Tokenise, 2 - Custom Payment. Defaults to 0 if not provided.' },
    { name: 'customerName', type: 'string', conditional: 'Conditional', remarks: 'Required if mode is set to 0 or 2.' },
    { name: 'customerReference', type: 'string', conditional: 'Conditional', remarks: 'Required if mode is set to 0 or 2.' },
    { name: 'paymentAmount', type: 'number', conditional: 'Conditional', remarks: 'Required if mode is set to 0 or 2. Returns applicable fee if provided with mode 1.' },
    { name: 'allowBankAcOneOffPayment', type: 'boolean', conditional: 'Conditional', remarks: 'Required if mode is set to 0 or 2. Default is false.' },
    { name: 'allowPayIdOneOffPayment', type: 'boolean', conditional: 'Conditional', remarks: 'Required if mode is set to 0 or 2. Default is false.' },
    { name: 'allowApplePayOneOffPayment', type: 'boolean', conditional: 'Conditional', remarks: 'Conditional if mode is set to 0. Default is false.' },
    { name: 'allowGooglePayOneOffPayment', type: 'boolean', conditional: 'Conditional', remarks: 'Conditional if mode is set to 0. Default is false.' },
    { name: 'allowLatitudePayOneOffPayment', type: 'boolean', conditional: 'Conditional', remarks: 'Conditional if mode is set to 0. Default is false.' },
    { name: 'showFeeOnTokenising', type: 'boolean', conditional: 'Conditional', remarks: 'Required if mode is set to 1. Default is false.' },
    { name: 'showFailedPaymentFeeOnTokenising', type: 'boolean', conditional: 'Conditional', remarks: 'Optional if mode is set to 1. Default is false.' },
    { name: 'merchantUniquePaymentId', type: 'string', conditional: 'Required', remarks: 'Payment id provided by the merchant. Must be unique.' },
    { name: 'timestamp', type: 'string', conditional: 'Conditional', remarks: 'Required for v4, optional for v3. Format: yyyy-MM-ddTHH:mm:ss in UTC.' },
    { name: 'cardProxy', type: 'string', conditional: 'Optional', remarks: 'Use to make a payment using a card proxy generated using mode 1.' },
    { name: 'callbackUrl', type: 'string', conditional: 'Optional', remarks: 'URL called with HTTP POST method to submit the result.' },
    { name: 'hideTermsAndConditions', type: 'boolean', conditional: 'Optional', remarks: 'Hide the Terms and Conditions. Defaults to false.' },
    { name: 'sendConfirmationEmailToMerchant', type: 'boolean', conditional: 'Optional', remarks: 'Send confirmation email to merchant. Defaults to false.' },
    { name: 'additionalReference', type: 'string', conditional: 'Optional', remarks: 'Additional reference to identify customer.' },
    { name: 'contactNumber', type: 'string', conditional: 'Optional', remarks: 'Contact number' },
    { name: 'customerEmail', type: 'string', conditional: 'Conditional', remarks: 'Email address for invoice. Mandatory in V4.' },
    { name: 'abn', type: 'string', conditional: 'Optional', remarks: 'Australian Business Number.' },
    { name: 'companyName', type: 'string', conditional: 'Optional', remarks: 'Customer company name.' },
    { name: 'title', type: 'string', conditional: 'Optional', remarks: 'Plugin Title. Defaults to "Process Payment".' },
    { name: 'hideHeader', type: 'boolean', conditional: 'Optional', remarks: 'Hide the program header including logo. Defaults to true.' },
    { name: 'hideMerchantLogo', type: 'boolean', conditional: 'Optional', remarks: 'Hide the merchant logo if any. Defaults to false.' },
    { name: 'overrideFeePayer', type: 'int', conditional: 'Optional', remarks: '0 - Default, 1 - Merchant, 2 - Customer. Defaults to 0.' },
    { name: 'userMode', type: 'int', conditional: 'Optional', remarks: '0 - Customer Facing (default), 1 - Merchant Facing.' },
    { name: 'minHeight', type: 'int', conditional: 'Optional', remarks: 'Defaults to 725px for Mode 0 and 2, 450px for mode 1.' },
    { name: 'onPluginClose', type: 'function', conditional: 'Optional', remarks: 'Javascript callback function to execute when plug-in is closed.' },
    { name: 'sendConfirmationEmailToCustomer', type: 'boolean', conditional: 'Optional', remarks: 'Send confirmation email to customer. Defaults to false.' },
];

const InputParametersTab: React.FC = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}
            className="space-y-4 p-4"
        >
            <h2 className="text-2xl font-bold mb-4">Input Parameters - Work in progress - ignore</h2>
            <div className="overflow-x-auto">
                <Table>
                    <thead>
                        <tr>
                            <Th>Field Name</Th>
                            <Th>Data Type</Th>
                            <Th>Conditional</Th>
                            <Th>Remarks</Th>
                        </tr>
                    </thead>
                    <tbody>
                        {inputParameters.map((param, index) => (
                            <tr key={index}>
                                <Td>{param.name}</Td>
                                <Td>{param.type}</Td>
                                <Td>{param.conditional}</Td>
                                <Td>{param.remarks}</Td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </motion.div>
    );
};

export default InputParametersTab;


// import React, { useState } from 'react';
// import { motion } from 'framer-motion';
// import styled from 'styled-components';
// import { ChevronDown, ChevronUp } from 'lucide-react';

// const Table = styled.table`
//   width: 100%;
//   border-collapse: separate;
//   border-spacing: 0 4px;
// `;

// const Th = styled.th`
//   text-align: left;
//   padding: 12px;
//   background-color: #4a90e2;
//   color: white;
//   font-weight: bold;
// `;

// const Td = styled.td`
//   padding: 12px;
//   background-color: #ffffff;
//   border: 1px solid #eee;
// `;

// const ToggleButton = styled.button`
//   display: flex;
//   align-items: center;
//   background-color: #4a90e2;
//   color: white;
//   padding: 10px;
//   border: none;
//   cursor: pointer;
//   width: 100%;
//   justify-content: space-between;
//   font-size: 1rem;
//   font-weight: bold;
// `;

// const inputParameters = [
//   { name: 'url', type: 'string', conditional: 'Required', remarks: 'Plugin access url. We strongly recommend v4 integration.' },
//   { name: 'merchantCode', type: 'string', conditional: 'Required', remarks: 'As provided by Zenith.' },
//   { name: 'apiKey', type: 'string', conditional: 'Required', remarks: 'As provided by Zenith' },
//   { name: 'fingerprint', type: 'string', conditional: 'Required', remarks: 'SHA3 (SHA512) hash (v4) or SHA1 hash (v3) of specific fields.' },
//   { name: 'redirectUrl', type: 'string', conditional: 'Required', remarks: 'The page will redirect to this URL with the result in the query string.' },
//   { name: 'mode', type: 'int', conditional: 'Optional', remarks: '0 - Make Payment, 1 - Tokenise, 2 - Custom Payment. Defaults to 0 if not provided.' },
//   { name: 'customerName', type: 'string', conditional: 'Conditional', remarks: 'Required if mode is set to 0 or 2.' },
//   { name: 'customerReference', type: 'string', conditional: 'Conditional', remarks: 'Required if mode is set to 0 or 2.' },
//   { name: 'paymentAmount', type: 'number', conditional: 'Conditional', remarks: 'Required if mode is set to 0 or 2. Returns applicable fee if provided with mode 1.' },
//   { name: 'allowBankAcOneOffPayment', type: 'boolean', conditional: 'Conditional', remarks: 'Required if mode is set to 0 or 2. Default is false.' },
//   { name: 'allowPayIdOneOffPayment', type: 'boolean', conditional: 'Conditional', remarks: 'Required if mode is set to 0 or 2. Default is false.' },
//   { name: 'allowApplePayOneOffPayment', type: 'boolean', conditional: 'Conditional', remarks: 'Conditional if mode is set to 0. Default is false.' },
//   { name: 'allowGooglePayOneOffPayment', type: 'boolean', conditional: 'Conditional', remarks: 'Conditional if mode is set to 0. Default is false.' },
//   { name: 'allowLatitudePayOneOffPayment', type: 'boolean', conditional: 'Conditional', remarks: 'Conditional if mode is set to 0. Default is false.' },
//   { name: 'showFeeOnTokenising', type: 'boolean', conditional: 'Conditional', remarks: 'Required if mode is set to 1. Default is false.' },
//   { name: 'showFailedPaymentFeeOnTokenising', type: 'boolean', conditional: 'Conditional', remarks: 'Optional if mode is set to 1. Default is false.' },
//   { name: 'merchantUniquePaymentId', type: 'string', conditional: 'Required', remarks: 'Payment id provided by the merchant. Must be unique.' },
//   { name: 'timestamp', type: 'string', conditional: 'Conditional', remarks: 'Required for v4, optional for v3. Format: yyyy-MM-ddTHH:mm:ss in UTC.' },
//   { name: 'cardProxy', type: 'string', conditional: 'Optional', remarks: 'Use to make a payment using a card proxy generated using mode 1.' },
//   { name: 'callbackUrl', type: 'string', conditional: 'Optional', remarks: 'URL called with HTTP POST method to submit the result.' },
//   { name: 'hideTermsAndConditions', type: 'boolean', conditional: 'Optional', remarks: 'Hide the Terms and Conditions. Defaults to false.' },
//   { name: 'sendConfirmationEmailToMerchant', type: 'boolean', conditional: 'Optional', remarks: 'Send confirmation email to merchant. Defaults to false.' },
//   { name: 'additionalReference', type: 'string', conditional: 'Optional', remarks: 'Additional reference to identify customer.' },
//   { name: 'contactNumber', type: 'string', conditional: 'Optional', remarks: 'Contact number' },
//   { name: 'customerEmail', type: 'string', conditional: 'Conditional', remarks: 'Email address for invoice. Mandatory in V4.' },
//   { name: 'abn', type: 'string', conditional: 'Optional', remarks: 'Australian Business Number.' },
//   { name: 'companyName', type: 'string', conditional: 'Optional', remarks: 'Customer company name.' },
//   { name: 'title', type: 'string', conditional: 'Optional', remarks: 'Plugin Title. Defaults to "Process Payment".' },
//   { name: 'hideHeader', type: 'boolean', conditional: 'Optional', remarks: 'Hide the program header including logo. Defaults to true.' },
//   { name: 'hideMerchantLogo', type: 'boolean', conditional: 'Optional', remarks: 'Hide the merchant logo if any. Defaults to false.' },
//   { name: 'overrideFeePayer', type: 'int', conditional: 'Optional', remarks: '0 - Default, 1 - Merchant, 2 - Customer. Defaults to 0.' },
//   { name: 'userMode', type: 'int', conditional: 'Optional', remarks: '0 - Customer Facing (default), 1 - Merchant Facing.' },
//   { name: 'minHeight', type: 'int', conditional: 'Optional', remarks: 'Defaults to 725px for Mode 0 and 2, 450px for mode 1.' },
//   { name: 'onPluginClose', type: 'function', conditional: 'Optional', remarks: 'Javascript callback function to execute when plug-in is closed.' },
//   { name: 'sendConfirmationEmailToCustomer', type: 'boolean', conditional: 'Optional', remarks: 'Send confirmation email to customer. Defaults to false.' },
// ];

// const InputParametersTab: React.FC = () => {
//   const [isTableVisible, setIsTableVisible] = useState(false);

//   return (
//     <motion.div
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       transition={{ duration: 0.5 }}
//       exit={{ opacity: 0 }}
//       className="space-y-4 p-4"
//     >
//       <h2 className="text-2xl font-bold mb-4">Input Parameters</h2>
//       <ToggleButton onClick={() => setIsTableVisible(!isTableVisible)}>
//         <span>Toggle Parameter Table</span>
//         {isTableVisible ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
//       </ToggleButton>
//       {isTableVisible && (
//         <div className="overflow-x-auto">
//           <Table>
//             <thead>
//               <tr>
//                 <Th>Field Name</Th>
//                 <Th>Data Type</Th>
//                 <Th>Conditional</Th>
//                 <Th>Remarks</Th>
//               </tr>
//             </thead>
//             <tbody>
//               {inputParameters.map((param, index) => (
//                 <tr key={index}>
//                   <Td>{param.name}</Td>
//                   <Td>{param.type}</Td>
//                   <Td>{param.conditional}</Td>
//                   <Td>{param.remarks}</Td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         </div>
//       )}
//     </motion.div>
//   );
// };

// export default InputParametersTab;