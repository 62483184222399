// ##### src/pages/TabContainer.tsx #####

import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import HashGenTab from './HashGenTab';
import ParserTab from './ParserTab';
import ErrorCodesTab from './ErrorCodesTab';
import InputParametersTab from './InputParametersTab';
import { InputFields } from '../types/inputTypes';
interface TabContainerProps {
  activeTab: string;
  handleTabChange: (value: string) => void;
  inputFields: InputFields;
  errors: Partial<Record<keyof InputFields, string>>;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  generateHash: () => void;
  isLoading: boolean;
  fieldInfo: Record<keyof InputFields, JSX.Element>;
  generatedHash: string;
  url: string;
  setUrl: (url: string) => void;
  parseUrl: () => void;
  parsedUrl: [string, string][];
}
const TabsContainer: React.FC<TabContainerProps> = ({
  activeTab,
  handleTabChange,
  inputFields,
  errors,
  onInputChange,
  generateHash,
  isLoading,
  fieldInfo,
  generatedHash,
  url,
  setUrl,
  parseUrl,
  parsedUrl
}) => {
  return (
    <Tabs value={activeTab} onValueChange={handleTabChange} className="space-y-4">
      <TabsList className="bg-red-100 flex justify-center rounded-sm overflow-hidden">
        <TabsTrigger value="hashgen" className="flex-1 text-center">
          🔢 Hash Generator
        </TabsTrigger>
        <TabsTrigger value="parser" className="flex-1 text-center">
          🔗 URL parser
        </TabsTrigger>
        <TabsTrigger value="errorcodes" className="flex-1 text-center">
          🛑 Error Codes
        </TabsTrigger>
        <TabsTrigger value="inputparams" className="flex-1 text-center">
          📝 Input Parameters
        </TabsTrigger>
      </TabsList>
      <TabsContent value="hashgen">
        <HashGenTab
          inputFields={inputFields}
          errors={errors}
          onInputChange={onInputChange}
          generateHash={generateHash}
          isLoading={isLoading}
          fieldInfo={fieldInfo}
          generatedHash={generatedHash}
        />
      </TabsContent>
      <TabsContent value="parser">
        <ParserTab url={url} setUrl={setUrl} parseUrl={parseUrl} parsedUrl={parsedUrl} />
      </TabsContent>
      <TabsContent value="errorcodes">
        <ErrorCodesTab />
      </TabsContent>
      <TabsContent value="inputparams">
        <InputParametersTab />
      </TabsContent>
    </Tabs>
  );
};
export default TabsContainer;
