// src/components/jQuery/ErrorCodesTable.tsx

import React from 'react';
import styled from 'styled-components';

const TableWrapper = styled.div`
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
`;

const Td = styled.td`
    padding: 8px;
    background-color: #ffffff;
    border: 1px solid #eee;
    font-size: 0.9rem;

    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
`;

const SectionTitle = styled.h3`
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 8px;
    text-align: left;
    border-bottom: 2px solid #4a90e2;
    padding-bottom: 4px;
`;

const ErrorCode = styled.span`
    font-weight: bold;
    color: #4a90e2;
`;

interface ErrorData {
    code: string;
    description: string;
}

interface ErrorCodesTableProps {
    errorData: Record<string, ErrorData[]>;
}

const ErrorCodesTable: React.FC<ErrorCodesTableProps> = ({ errorData }) => {
    return (
        <TableWrapper>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(errorData).map(([section, errors]) => (
                    <div key={section}>
                        <SectionTitle>{section.replace(/([A-Z])/g, ' $1').trim()}</SectionTitle>
                        <Table>
                            <tbody>
                                {errors.map((error, index) => (
                                    <tr key={index}>
                                        <Td><ErrorCode>{error.code}</ErrorCode></Td>
                                        <Td>{error.description}</Td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                ))}
            </div>
        </TableWrapper>
    );
};

export default ErrorCodesTable;