// src/lib/deviceFingerprint.ts

export interface DeviceFingerprint {
	screenInfo: {
		width: number;
		height: number;
		colorDepth: number;
		pixelRatio: number;
		windowWidth: number;
		windowHeight: number;
	};
	browserInfo: {
		userAgent: string;
		platform: string;
		language: string;
		cookiesEnabled: boolean;
		doNotTrack: string | null;
	};
	connectionInfo:
		| string
		| {
				effectiveType?: string;
				downlink?: number;
				rtt?: number;
		  };
	timezoneInfo: {
		timezone: string;
		offset: number;
	};
}

export function getDeviceFingerprint(): DeviceFingerprint {
	let connectionInfo: DeviceFingerprint['connectionInfo'] = 'Not available';
	if (typeof navigator !== 'undefined' && 'connection' in navigator) {
		const connection = (navigator as any).connection;
		if (connection) {
			connectionInfo = {
				effectiveType: connection.effectiveType,
				downlink: connection.downlink,
				rtt: connection.rtt
			};
		}
	}
	return {
		screenInfo: {
			width: window.screen.width,
			height: window.screen.height,
			colorDepth: window.screen.colorDepth,
			pixelRatio: window.devicePixelRatio,
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight
		},
		browserInfo: {
			userAgent: navigator.userAgent,
			platform: navigator.platform,
			language: navigator.language,
			cookiesEnabled: navigator.cookieEnabled,
			doNotTrack: navigator.doNotTrack
		},
		connectionInfo,
		timezoneInfo: {
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			offset: new Date().getTimezoneOffset()
		}
	};
}
